<template>
  <div>
    <div v-if="videos != ''">
      <div class="videos-wrapper"
           v-for="(video, index) in videos"
           :key="index">
        <div class="row">
          <div class="col-12">
            <div class="video-container">
              <!-- Top Overlay -->
              <div class="d-flex justify-content-between top-overlay">
                <div>
                  <p class="user-name mb-0">
                    <img class="user-image"
                         :src="video.profile_img_url"
                         alt="user-image"
                         v-if="video.profile_img_url" />
                    <img class="user-image"
                         src="../../../assets/images/sample_student_profile.png"
                         alt="user-image"
                         v-else />
                    {{ video.nickname }}
                  </p>
                </div>
                <!-- Approve, Reject, & Report Icon -->
                <div class="video-actions">
                  <i class="fas fa-check approve-icon"
                    @click="updateStatus(video, 'approved')"
                    title="Approve Video">
                  </i>
                  <br>
                  <i class="fas fa-times reject-icon"
                    @click="updateStatus(video, 'rejected')"
                    title="Reject Video">
                  </i>
                  <br>
                  <i class="fas fa-flag report-icon"
                     @click="reportVideo(video)"
                     title="Report Video">
                  </i>
                </div>
              </div>

              <!-- Video -->
              <vue-plyr class="pending-video">
                <video class="video-player"
                       :src="video.url"
                       :style="{height: `${vpHeight}px`}"
                       :poster="video.url">
                  <source :src="video.url" type="video/mp4" size="720">
                  <source :src="video.url" type="video/mp4" size="1080">
                </video>
              </vue-plyr>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col">
            <hr class="white">
          </div>
        </div>
      </div>

      <!-- Load More -->
      <div class="row" v-if="videosCurrentPage != videosLastPage">
        <div class="col text-center">
          <b-button class="btn btn-h3-small btn-h3-blue mb-2 mt-2"
                    type="button"
                    @click="loadMoreVideos">
            Load More
          </b-button>
        </div>
      </div>
    </div>


    <!-- No Pending Videos -->
    <div class="row" v-else>
      <div class="col">
        <p class="mt-2 text-center">No pending videos</p>
      </div>
    </div>
  </div>
</template>

<script>
  const ReportContent = () => import('@/components/modals/ReportContent.vue');

  export default {
    name : 'PendingVideos',
    data() {
      return {
        videos            : [],
        videosPerPage     : 10,
        videosCurrentPage : 0,
        videosLastPage    : 0,
        colWidth          : 0,
        vpHeight          : 0,
      }
    },
    updated() {
      this.getColumnWidth();
    },
    mounted() {
      this.getPendingVideos(0);
      this.getColumnWidth();
    },
    watch : {
      '$route'() {
        this.getPendingVideos(0);
      },

      colWidth : function() {
        this.vpHeight = this.colWidth > 0 ? this.colWidth * 0.75 : 0;
      },
    },
    methods : {

      /**
       * Get all pending videos per Classroom
       * @param currentPage Pagination's current page
       */
      getPendingVideos(currentPage = this.videosCurrentPage) {
        this.$http.get('api/approval_request/videos', {
          params : {
            page        : currentPage + 1,
            perPage     : this.videosPerPage,
            schoolId    : this.$store.getters['user/user'].school_id,
            classroomId : this.$route.params.classroomId,
          },
        }).then(response => {
          this.videosCurrentPage = response.data.currentPage;
          this.videosLastPage    = response.data.lastPage;

          if (this.videosCurrentPage > 1) {
            const arr1 = this.videos;
            const arr2 = response.data.data;

            this.videos = arr1.concat(arr2);
          } else this.videos = response.data.data;
        }).catch(() => {
          this.videos = [];
        });
      },

      /**
       * Load more videos
       */
      loadMoreVideos() {
        this.getPendingVideos();
      },

      /**
       * Approve or Reject Image request
       * @param video Video data
       * @param action Action to make (i.e. 'approved' or 'rejected')
       */
      updateStatus(video, action) {
        this.$http.put('api/approval_request', {
          "status"            : action,
          "userId"            : this.$store.getters['user/user'].user_id,
          "approvalRequestId" : video.approval_request_id,
        }).then(() => {
          /**
           * TODO: Find other way to update the ff.
           */
          // 1. Pending Video List
          this.getPendingVideos(0); // reset currentPage to 0

          // 2. Classroom Image List on Grand Parent via Parent component
          //    Classroom.vue -> Grand Parent
          //    PendingApproval.vue -> Parent Component
          this.$emit('update-parent-video');

          const studentName = video.nickname;
          this.$notify({
            group : 'notif',
            type  : (action === 'approved') ? 'success' : 'warn',
            text  : (action === 'approved') ? 'Approved Video from ' +
              studentName : 'Rejected Video from ' + studentName,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Get Column's Width
       */
      getColumnWidth() {
        this.$nextTick(() => {
          const $colId = this.$el.getElementsByClassName('videos-wrapper')[0];
          if ($colId)
            this.colWidth = $colId.clientWidth;
        });
      },

      /**
       * Report Video
       * @param video
       */
      reportVideo(video) {
        this.$modal.show(ReportContent, {
          mediaData : video,
          type      : 'video',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/classroom/pending-videos";
</style>
